import React from 'react';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

import SectionContent from './SectionContent/SectionContent';
import SectionFooter from './SectionFooter/SectionFooter';
import SectionHeader from './SectionHeader/SectionHeader';

const HomeServices = ({ sectionTitle, dataServices, sectionHours }) => {
  const startRotate = React.createRef();
  const rotatateElement = React.createRef();

  // React.useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: startRotate.current,
  //       start: 'bottom+=0 bottom',
  //       end: 'top+=0 center+=10%',
  //       scrub: true,
  //       // pin: pinnedStuff.current,
  //       markers: true,
  //     },
  //   });
  //   tl.to(startRotate.current, {
  //     // rotation: 360 * 6,
  //     // transformOrigin: '50% 50%',
  //     // duration: 0.25,
  //     x: 300,
  //   });
  // }, []);

  return (
    <section
      id="down-triger"
      className="home-services"
      data-scroll-section
      ref={startRotate}
    >
      <div className="container-fluid">
        <SectionHeader
          sectionTitle={sectionTitle}
          rotatateElement={rotatateElement}
        />
        <SectionContent dataServices={dataServices} />
        <SectionFooter sectionHours={sectionHours} />
      </div>
    </section>
  );
};

export default HomeServices;
