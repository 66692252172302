import React, { useRef } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import useLanguage from '../../../../../Hooks/useLanguage';

import ArrowLeft from '../../../../../../svg/arrow_left.svg';
import ArrowRight from '../../../../../../svg/arrow_right.svg';

const NewsSlider = ({dataNews}) => {
  const langToggle = useLanguage;
  const slider = useRef(null);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        // className={className}
        className="slider-button slider-button__next"
        onClick={onClick}
      >
        Next
        <ArrowRight />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <button className="slider-button slider-button__prev" onClick={onClick}>
        Prev
        <ArrowLeft />
      </button>
    );
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
    ],
  };

  return (
    <Slider ref={slider} {...settings} className="news-slider">
      {dataNews.map((slide, index) => {
        const count = index + 1;
        return (
          <div className="news-slider__slide" key={index}>
            <Link to={'/trainings/' + slide.link} className="news-slider__link">
              <span className="slide-count">{'0' + count}</span>
              <span className="slide-title">
                {langToggle(
                  slide.home_news_ua,
                  slide.home_news_ru,
                  slide.home_news_en
                )}
              </span>
            </Link>
          </div>
        );
      })}
    </Slider>
  );
};

export default NewsSlider;
