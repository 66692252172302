import React from 'react';

import HomePartnersHeader from './HomePartnersHeader/HomePartnersHeader';
import VideoPlayer from '../../../UI/VideoPlayer/VideoPlayer';
import VideoModal from '../../../UI/VideoModal/VideoModal';

const HomePartners = ({ dataPartners }) => {
 
  const [open, setOpen] = React.useState(false);
  const [play, setPlay] = React.useState(false);

  function openModal() {
    setOpen(!open);
    setPlay(true);
  }

  function closeModal(e) {
    e.preventDefault();
    setOpen(false);
    setPlay(false);
  }

  return (
    <>
      <section className="home-partners" data-scroll-section>
        <div className="container-fluid">
          <HomePartnersHeader dataPartners={dataPartners} />
          <VideoPlayer
            dataPartners={dataPartners}
            openModal={openModal}
          />
        </div>
      </section>
      <VideoModal
        closeModal={closeModal}
        play={play}
        open={open}
        video={dataPartners.video_link}
      />
    </>
  );
};

export default HomePartners;
