import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";


// import Play from '../../../../../svg/play.svg';

const HomeAboutVideo = ({ openModal, dataAbout }) => {

  const image = getImage(dataAbout.image_cover.localFile);

  return (
    <div
      className="home-about__right"
      // onClick={openModal}
      role="presentation"
      data-scroll
      data-scroll-speed="1.5"
    >
      <div className="video-cover-wrapper" data-scroll data-scroll-speed="-1.5">
        <GatsbyImage
          className="home-video__cover"
          image={image}
          alt="about"
        />
      </div>
      {/* <button className="play">
        <i className="play-icon">
          <Play />
        </i>
        <span className="rings">
          <span className="ring__ringlet"></span>
          <span className="ring__ringlet"></span>
          <span className="ring__ringlet"></span>
        </span>
      </button> */}
    </div>
  );
};

export default HomeAboutVideo;
