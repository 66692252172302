import React from 'react';
import VideoModal from '../../../UI/VideoModal/VideoModal';

import HomeAboutContent from './HomeAboutContent/HomeAboutContent';
import HomeAboutVideo from './HomeAboutVideo/HomeAboutVideo';

const HomeAbout = ({ dataAbout }) => {

  const [open, setOpen] = React.useState(false);
  const [play, setPlay] = React.useState(false);

  function openModal() {
    setOpen(!open);
    setPlay(true);
  }

  function closeModal(e) {
    e.preventDefault();
    setOpen(false);
    setPlay(false);
  }
  return (
    <>
      <section className="home-about" data-scroll-section>
        <HomeAboutContent dataAbout={dataAbout} />
        <HomeAboutVideo openModal={openModal} dataAbout={dataAbout} />
      </section>
      <VideoModal
        closeModal={closeModal}
        play={play}
        open={open}
        video={dataAbout.video_link}
      />
    </>
  );
};

export default HomeAbout;
