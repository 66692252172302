import React from 'react';

import useLanguage from '../../../../Hooks/useLanguage';

import LicenseIcon from '../../../../../svg/license_icon.svg';
import LicenseUa from '../../../../../svg/license_ua.svg';
import LicenseRu from '../../../../../svg/license_ru.svg';
import LicenseEn from '../../../../../svg/license_en.svg';

const SectionHeader = ({ sectionTitle, rotatateElement }) => {
  const langToggle = useLanguage;

  return (
    <div className="section-header">
      <div className="section-header__left">

          <span className="section-title">
            {langToggle(
              sectionTitle.title_ua,
              sectionTitle.title_ru,
              sectionTitle.title_en
            )}
          </span>
          <h2 className="section-description">
            {langToggle(
              sectionTitle.title.titile_ua,
              sectionTitle.title.titile_ru,
              sectionTitle.title.titile_en
            )}
          </h2>
      </div>
      <div className="section-header__right">
        <div className="license-icon__wrapper">
          <LicenseIcon className="license-icon" />
          {langToggle(
            <LicenseUa className="license-text" />,
            <LicenseRu className="license-text" />,
            <LicenseEn className="license-text" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
