import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ButtonLink from '../../../UI/ButtonLink';

const HomeFounder = ({ dataFounder }) => {
  const langToggle = useLanguage;

  const image = getImage(dataFounder.title.image.localFile);


  return (
    <section className="home-founder" data-scroll-section>
      <div className="home-founder__left">
        <GatsbyImage
          className="home-founder-image"
          image={image}
          alt={langToggle(
            dataFounder.title.title_ua,
            dataFounder.title.title_ru,
            dataFounder.title.title_en
          )}
        />
      </div>
      <div className="home-founder__right">
        <span className="section-title">
          {langToggle(
            dataFounder.section_title_ua,
            dataFounder.section_title_ru,
            dataFounder.section_title_en
          )}
        </span>
        <h2 className="section-description">
          {langToggle(
            dataFounder.title.title_ua,
            dataFounder.title.title_ru,
            dataFounder.title.title_en
          )}
        </h2>
        <p className="section-text">
          {langToggle(
            dataFounder.content_ua,
            dataFounder.content_ru,
            dataFounder.content_en
          )}
        </p>
        <ButtonLink
          to={'/founder/'}
          title={langToggle('Дізнатись Більше', 'Узнать больше', 'Learn More')}
        />
      </div>
    </section>
  );
};

export default HomeFounder;
