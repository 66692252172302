import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';
import ButtonLink from '../../../../UI/ButtonLink';

const HomeAboutContent = ({ dataAbout }) => {
  const langToggle = useLanguage;
  return (
    <div className="home-about__left">
      <span className="section-title">
        {langToggle(
          dataAbout.section_title_ua,
          dataAbout.section_title_ru,
          dataAbout.section_title_en
        )}
      </span>
      <h2 className="section-description">
        {langToggle(dataAbout.title_ua, dataAbout.title_ru, dataAbout.title_en)}
      </h2>
      <p className="home-about__text">
        {langToggle(
          dataAbout.description_ua,
          dataAbout.description_ru,
          dataAbout.description_en
        )}
      </p>
      <ButtonLink
        to={'/about/'}
        title={langToggle(
          'Більше про клініку',
          'Подробнее о клинике',
          'More about the clinic'
        )}
      />
    </div>
  );
};

export default HomeAboutContent;
