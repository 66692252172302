import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const HomeValuesContent = ({ dataValues }) => {
  const langToggle = useLanguage;
  return (
    <div className="value-section__content">
      <div className="home-values__items">
        {dataValues.Values.map((item, index) => {
          return (
            <div className="home-value__item" key={index}>
              <div className="item-header">
                <span className="item-title">
                  {langToggle(item.title_ua, item.title_ru, item.title_en)}
                </span>
              </div>
              <p className="item-text">
                {langToggle(item.values_ua, item.values_ru, item.values_en)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeValuesContent;
