import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import HomeMain from '../components/Pages/Home/HomeMain/HomeMain';
import HomeServices from '../components/Pages/Home/HomeServices/HomeServices';
import HomeAbout from '../components/Pages/Home/HomeAbout/HomeAbout';
import HomeValues from '../components/Pages/Home/HomeValues/HomeValues';
import HomeFounder from '../components/Pages/Home/HomeFounder/HomeFounder';
import HomeTrainings from '../components/Pages/Home/HomeTrainings/HomeTrainings';
import useLanguage from '../components/Hooks/useLanguage';
// import dataHome from '../db/dataHome';
// import dataServices from '../db/dataServices';
// import dataTrainings from '../db/dataTrainings';
import HomeGallery from '../components/Pages/Home/HomeGallery/HomeGallery';
import HomeQuote from '../components/Pages/Home/HomeQuote/HomeQuote';
import Instagram from '../components/Layout/Instagram/Instagram';
import HomePartners from '../components/Pages/Home/HomePartners/HomePartners';

const IndexPage = ({ data }) => {
  // const trainingsDb = dataTrainings;
  const langToggle = useLanguage;
  const seo = data.strapiHome.seo
  return (
    <Layout whitwHeader={'active'}>
     <Seo 
        title={langToggle(seo.seo_title_ua, seo.seo_title_ru, seo.seo_title_en)} 
        description={langToggle(seo.seo_description_ua, seo.seo_description_ru, seo.seo_description_en)} 
     /> 
      <HomeMain
        dataBackground={data.strapiHome.Background_image}
        dataTitle={data.strapiHome.Background_title}
        dataNews={data.strapiHome.News}
      />
      <HomeServices
        sectionTitle={data.strapiHome.srvices_title}
        dataServices={data.allStrapiServicesCategories.nodes}
        sectionHours={data.strapiHome.opening_hours}
      />
      <HomeAbout dataAbout={data.strapiHome.about} />
      <HomeValues dataValues={data.strapiHome.values_title} />
      <HomeFounder dataFounder={data.strapiHome.oksana} />
      <HomeTrainings
        trainingsTitle={data.strapiHome.trening_title}
        dataTraining={data.allStrapiTrenings}
      />
      <HomePartners dataPartners={data.strapiHome.partners} />
      <HomeGallery dataGallery={data.strapiHome.gaellery_title} />
      <HomeQuote dataQuote={data.strapiHome.quotation} />
      <Instagram />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePage($language: String!) {
    strapiHome {
      seo {
        seo_title_ua
        seo_title_ru
        seo_title_en
        seo_description_ua
        seo_description_ru
        seo_description_en
    }
      Background_title {
        title_ua
        title_ru
        title_en
      }
      Background_image {
      video {
        url
      }
      image {
        bg_image {
          localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
          }
        }
        # mobile_img {
        #   localFile {
        #       childImageSharp {
        #         gatsbyImageData(
        #           placeholder: BLURRED
        #           formats: [AUTO, WEBP, AVIF]
        #           layout: FULL_WIDTH
        #         )
        #       }
        #   }
        # }
      }
    }
    News {
      home_news_ua
      home_news_ru
      home_news_en
      link
    }
    srvices_title {
      section_title_ua
      section_title_ru
      section_title_en
      title {
        titile_ua
        titile_ru
        titile_en
      }
    }
    opening_hours {
      title_ua
      title_ru
      title_en
      }
      about {
        section_title_ua
        section_title_ru
        section_title_en
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
        image_cover {
          localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
          }
        }
        video_link
      }
      values_title {
        section_title_ua
        section_title_ru
        section_title_en
        title {
          title_ua
          title_ru
          title_en
        }
        Values {
          title_ua
          title_ru
          title_en
          values_ua
          values_ru
          values_en
        }
      }
      oksana {
        section_title_ua
        section_title_ru
        section_title_en
        title {
          title_ua
          title_ru
          title_en
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        content_ua
        content_ru
        content_en
      }
      trening_title {
        section_title_ua
        section_title_ru
        section_title_en
        title {
          title_ua
          title_ru
          title_en
        }
      }
      partners {
        section_title_ua
        section_title_ru
        section_title_en
        title_ua
        title_ru
        title_en
        video_link
        image_cover {
          localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
        }
      }
      gaellery_title {
        section_title_ua
        section_title_ru
        section_title_en
        title {
          title_ua
          title_ru
          title_en
        }
        gallery {
          image {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
         }
      }
      quotation {
        title_ua
        title_ru
        title_en
        quotation_ua
        quotation_ru
        quotation_en
      }
    }
    allStrapiServicesCategories {
      nodes {
        title_ua
        title_ru
        title_en
        slug
        icon {
          url
        }
      }
    }
    allStrapiTrenings {
      nodes {
      title_ua
      title_ru
      title_en
      about_description_ua
      about_description_ru
      about_description_en
      slug
      Data
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

