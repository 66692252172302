import React, { useRef } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import useLanguage from '../../../Hooks/useLanguage';
import ButtonLink from '../../../UI/ButtonLink';

const HomeTrainings = ({ trainingsTitle, dataTraining }) => {

  const langToggle = useLanguage;

  const slider = useRef(null);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
  };

  return (
    <section className="home-trainings" data-scroll-section>
      <div className="home-trainings__header">
        <span className="section-title">
          {langToggle(
            trainingsTitle.section_title_ua,
            trainingsTitle.section_title_ru,
            trainingsTitle.section_title_en
          )}
        </span>
        <h2 className="section-description">
          {langToggle(
            trainingsTitle.title.title_ua,
            trainingsTitle.title.title_ru,
            trainingsTitle.title.title_en
          )}
        </h2>
      </div>
      <Slider ref={slider} {...settings} className="trainings-slider">
        {dataTraining.nodes.map((item, index) => {
        
        const image = getImage(item.image.localFile);

          return (
            <div className="training-slider__slide" key={index}>
              <div className="trainings-slide__wrapper">
                <div className="slide-contnet__left">
                  <span className="training-date__wrapper">
                    <span className="training-date">{item.Data}</span>
                  </span>
                  <h3 className="training-title">
                    {langToggle(item.title_ua, item.title_ru, item.title_en)}
                  </h3>
                  <p className="section-text">
                    {langToggle(
                      item.about_description_ua,
                      item.about_description_ru,
                      item.about_description_en
                    )}
                  </p>
                  <ButtonLink
                    to={'/' + item.slug}
                    title={langToggle(
                      'Дізнатись Більше',
                      'Узнать больше',
                      'Learn More'
                    )}
                  />
                </div>
                <div className="slide-content__right">
                  <GatsbyImage
                    className="home-training-image"
                    image={image}
                    alt={langToggle(item.title_ua, item.title_ru, item.title_en)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="home-trainings__footer">
        <ButtonLink
          to={'/trainings/'}
          title={langToggle(
            'Дивитись всі тренінги',
            'Смотреть все тренинги',
            'See all trainings'
          )}
        />
      </div>
    </section>
  );
};

export default HomeTrainings;
