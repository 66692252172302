import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useLanguage from '../../../Hooks/useLanguage';

import HomeMainBottom from './HomeMainBottom/HomeMainBottom';

const HomeMain = ({ dataBackground, dataTitle, dataNews }) => {
  const langToggle = useLanguage;
  const image = getImage(dataBackground.image.bg_image.localFile);

  return (
    <section
      id="scroller-top"
      className="home-main-screen scroller-top"
      data-scroll-section
    >
      <div className="main-screen-bg">
        <video
          className="video-bg"
          src={`https://admin.oxx-clinic.com.ua${dataBackground.video.url}`}
          playsInline=""
          webkit-playsinline=""
          autoPlay={true}
          muted={true}
          loop={true}
          data-scroll
          data-scroll-speed="-2"
        ></video>
        <GatsbyImage
          className="mobile-bg"
          data-scroll
          data-scroll-speed="-2"
          image={image}
          alt={langToggle(
            dataTitle.title_ua,
            dataTitle.title_ru,
            dataTitle.title_en
          )}
        />
      </div>
      <div className="home-main-center">
        <h1 className="h1">
          {langToggle(
            dataTitle.title_ua,
            dataTitle.title_ru,
            dataTitle.title_en
          )}
        </h1>
      </div>
      <HomeMainBottom dataNews={dataNews} />
    </section>
  );
};

export default HomeMain;
