import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

import ButtonLink from '../../../../UI/ButtonLink';
import ButtonBook from '../../../../UI/ButtonBook';

const HomeValuesFooter = () => {
  const langToggle = useLanguage;

  function book() {
    console.log('Test Succes');
  }

  return (
    <div className="value-section-footer">
      <ButtonLink
        to={'/about/'}
        title={langToggle(
          'Більше про клініку',
          'Подробнее о клинике',
          'More about the clinic'
        )}
      />
      <ButtonBook
        clickHendler={book}
        title={langToggle(
          'Записатись на консультацію',
          'Записаться на консультацию',
          'Book a Consultation'
        )}
      />
    </div>
  );
};

export default HomeValuesFooter;
