import React from 'react';

import useLanguage from '../../../../Hooks/useLanguage';
import ButtonBook from '../../../../UI/ButtonBook';
import ButtonLink from '../../../../UI/ButtonLink';

const SectionFooter = ({ sectionHours }) => {
  const langToggle = useLanguage;
  function book() {
    console.log('Test Succes');
  }
  return (
    <div className="section-footer">
      <div className="section-footer__left">
          <p className="work-hours">
            {langToggle('Працюємо:', 'Работаем:', 'We work:')}
            <span className="hours">
              {langToggle(
                sectionHours.title_ua,
                sectionHours.title_ru,
                sectionHours.title_en
              )}
            </span>
          </p>
      </div>
      <div className="section-footer__right">
          <ButtonLink
            to={'/prices'}
            title={langToggle('Дивитись ціни', 'Смотреть цены', 'See prices')}
          />
          <ButtonBook
            clickHendler={book}
            title={langToggle(
              'Записатись на консультацію',
              'Записаться на консультацию',
              'Book a Consultation'
            )}
          />
      </div>
    </div>
  );
};

export default SectionFooter;
