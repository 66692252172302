import React from 'react';
import { scroller } from 'react-scroll';

import NewsSlider from './NewsSlider/NewsSlider';

import ArrowDown from '../../../../../svg/arrow_down.svg';

const HomeMainBottom = ({ dataNews }) => {
  function scrollToSection() {
    scroller.scrollTo('home-services', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  return (
    <div className="main-bottom__wrapper">
      <div className="container-fluid">
        <div className="main-bottom">
          <button
            // href="#down-triger"
            className="scroll-down"
            data-scroll-to
            onClick={scrollToSection}
          >
            <ArrowDown />
          </button>
          <NewsSlider dataNews={dataNews} />
        </div>
      </div>
    </div>
  );
};

export default HomeMainBottom;
