import React from 'react';

import HomeValuesContent from './HomeValuesContent/HomeValuesContent';
import HomeValuesFooter from './HomeValuesFooter/HomeValuesFooter';
import HomeValuesHeader from './HomeValuesHeader/HomeValuesHeader';

const HomeValues = ({ dataValues }) => {
  return (
    <section className="home-values" data-scroll-section>
      <div className="container-fluid">
        <HomeValuesHeader dataValues={dataValues} />
        <HomeValuesContent dataValues={dataValues} />
        <HomeValuesFooter />
      </div>
    </section>
  );
};

export default HomeValues;
