import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const HomePartnersHeader = ({ dataPartners }) => {
  const langToggle = useLanguage;
  return (
    <div className="home-partners__header">
      <span className="section-title">
        {langToggle(
          dataPartners.section_title_ua,
          dataPartners.section_title_ru,
          dataPartners.section_title_en
        )}
      </span>
      <h2 className="section-description">
        {langToggle(
          dataPartners.title_ua,
          dataPartners.title_ru,
          dataPartners.title_en
        )}
      </h2>
    </div>
  );
};

export default HomePartnersHeader;
