import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../../Hooks/useLanguage';
import { fromCategorySlugToUrl } from "../../../../../utils/utils";

const SectionContent = ({ dataServices }) => {
  const langToggle = useLanguage;
  return (
    <div className="home-services__items">
      {dataServices.map((item, index) => {
        return (
          <div
            className="home-services__item"
            key={index}
            // data-scroll
            // data-scroll-speed="3"
          >
              <Link to={fromCategorySlugToUrl(item.slug)} className="home-services__link">
                <span className="services-item__wrapper">
                  <span className="item-icon__wrapper">
                    <img className="item-icon" 
                      src={`https://admin.oxx-clinic.com.ua${item.icon.url}`} 
                      alt={langToggle(item.title_ua, item.title_ru, item.title_en)} 
                    />
                  </span>
                  <span className="item-title__wrapper">
                    <span className="item-title">
                      {langToggle(item.title_ua, item.title_ru, item.title_en)}
                    </span>
                  </span>
                </span>
              </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SectionContent;
